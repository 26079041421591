:global(.scroll-y),
.scroll-y {
  overflow-y: auto;
}
:global(.scroll-x) {
  overflow-x: auto;
}

:global(.scroll-show::-webkit-scrollbar) {
  width: 15px;
  min-height: 10px;
}

:global(.scroll-show--hover::-webkit-scrollbar) {
  display: none;
}
:global(.scroll-show--hover:hover::-webkit-scrollbar) {
  display: inherit;
}

:global(.scroll-show::-webkit-scrollbar-thumb) {
  border: 4px solid transparent;
  border-radius: 7px;
  background-clip: padding-box;
  background-color: var(--color-bg-medium);
}

:global(.scroll-show::-webkit-scrollbar-button) {
  width: 0;
  height: 0;
  display: none;
}
:global(.scroll-show::-webkit-scrollbar-corner) {
  background-color: transparent;
}

:global(.scroll-show:hover::-webkit-scrollbar-thumb) {
  background-color: var(--color-bg-dark);
}
:global(.scroll-show::-webkit-scrollbar-thumb:horizontal:hover),
:global(.scroll-show::-webkit-scrollbar-thumb:vertical:hover) {
  background-color: var(--color-bg-dark);
}
:global(.scroll-show::-webkit-scrollbar-thumb:horizontal:active),
:global(.scroll-show::-webkit-scrollbar-thumb:vertical:active) {
  background-color: var(--color-bg-dark);
}

/* scroll light */
:global(.scroll-show.scroll--light::-webkit-scrollbar-thumb) {
  border-radius: 0;
  background-color: var(--color-bg-medium);
}

:global(.scroll-show.scroll--light::-webkit-scrollbar-thumb:horizontal:hover),
:global(.scroll-show.scroll--light::-webkit-scrollbar-thumb:vertical:hover),
:global(.scroll-show.scroll--light::-webkit-scrollbar-thumb:horizontal:active),
:global(.scroll-show.scroll--light::-webkit-scrollbar-thumb:vertical:active) {
  background-color: var(--color-bg-medium);
}

:global(.scroll-hide) {
  -ms-overflow-style: none; /* IE 10+ */
  overflow: -moz-scrollbars-none; /* Firefox */
}
:global(.scroll-hide::-webkit-scrollbar) {
  display: none; /* Safari and Chrome */
}

:global(.scroll-hide-all),
:global(.scroll-hide-all *) {
  -ms-overflow-style: none; /* IE 10+ */
  overflow: -moz-scrollbars-none; /* Firefox */
  scrollbar-width: none; /* Firefox */
}
:global(.scroll-hide-all::-webkit-scrollbar),
:global(.scroll-hide-all *::-webkit-scrollbar) {
  display: none; /* Safari and Chrome */
}
