.sidebar {
  composes: py2 from "style";
  width: 30%;
  max-width: 500px;
  background-color: var(--color-bg-light);
  color: var(--color-text-medium);
}

.sidebar a {
  text-decoration: none;
}

.item,
.sectionTitle {
  composes: py1 mb1 from "style";
  align-items: center;
  display: flex;
}

.item {
  composes: rounded pl2 from "style";
  font-size: 1em;
  color: var(--color-text-light);
}

.item .icon {
  line-height: 1em;
}

.sectionTitle {
  composes: my1 text-bold from "style";
  font-size: 16px;
}

.item:hover,
.sectionTitle:hover {
  background-color: var(--color-bg-medium);
  color: var(--color-brand);
}

.item.selected,
.item.selected .icon,
.sectionTitle.selected {
  color: var(--color-brand);
}
.item.selected,
.sectionTitle.selected {
  background-color: rgba(80, 158, 227, 0.15);
}

.divider {
  composes: my2 border-bottom from "style";
}

.name {
  composes: ml2 text-bold from "style";
  color: var(--color-text-medium);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}

.item:hover .name,
.item.selected .name {
  color: var(--color-brand);
}

.icon {
  flex-shrink: 0;
}
