:global @keyframes progress-bar{
  from{
    transform:translate3d(0, 0, 0, 0);
  }
  to{
    transform:translate3d(1000px, 0, 0);
  }
}
:global(.arrow-right){
  position:relative;
}
:global(.arrow-right:before),
:global(.arrow-right:after){
  position:absolute;
  content:"";
  display:block;
  border-left:10px solid transparent;
  border-right:10px solid transparent;
  border-top:10px solid transparent;
  border-bottom:10px solid transparent;

  top:50%;
  margin-top:-10px;
}
:global(.arrow-right:before){
  right:-20px;
  border-left-color:#eeecec;
}
:global(.arrow-right:after){
  right:-19px;
  border-left-color:#ffffff;
}
html{
  height:100%;
  width:100%;
}
@media print and (orientation: portrait){
  html{
    width:8.5in;
  }
}
@media print and (orientation: landscape){
  html{
    width:11in;
  }
}
body{
  font-family:var(--default-font-family), sans-serif;
  font-size:1em;
  font-weight:400;
  font-style:normal;
  color:#4c5773;
  margin:0;
  height:100%;
  display:flex;
  flex-direction:column;
  background-color:#f9fbfc;

  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
}
ul,
ol{
  padding:0;
  margin:0;
  list-style-type:none;
}
th{
  text-align:left;
}
button{
  font-size:100%;
  -webkit-appearance:none;
  border:0;
  padding:0;
  margin:0;
  outline:none;
  background-color:transparent;
}
a{
  color:inherit;
  cursor:pointer;
  text-decoration:none;
}
button,
input,
textarea{
  font-family:var(--default-font-family), "Helvetica Neue", Helvetica, sans-serif;
}
textarea{
  min-height:110px;
}
:global(.pointer-events-none),
:global(.pointer-events-none *){
  pointer-events:none;
}
:global(.disabled),
.disabled{
  pointer-events:none;
  opacity:0.4;
}
:global(.faded),
.faded{
  opacity:0.4;
}
:global(.fade-in-hover:hover){
  opacity:1;
  transition:opacity 0.3s;
}
:global(.circle){
  border-radius:99px;
}
:global(.bordered),
.bordered{
  border:1px solid #eeecec;
}
:global(.border-bottom),
.border-bottom{
  border-bottom:1px solid #eeecec;
}
:global(.bordered > .border-bottom:last-child){
  border-bottom:none;
}
:global(.border-top),
.border-top{
  border-top:1px solid #eeecec;
}
:global(.bordered > .border-top:first-child){
  border-top:none;
}
:global(.border-row-divider){
  border-bottom:1px solid #eeecec;
}
:global(.border-row-divider:last-child){
  border-bottom:none;
}
:global(.border-right){
  border-right:1px solid #eeecec;
}
:global(.border-left){
  border-left:1px solid #eeecec;
}
:global(.border-light){
  border-color:rgba(238, 236, 236, 0.2) !important;
}
:global(.border-error),
.border-error{
  border-color:#ed6e6e !important;
}
:global(.border-success){
  border-color:#84bb4c !important;
}
:global(.border-brand),
.border-brand{
  border-color:var(--color-brand) !important;
}
:global(.border-transparent){
  border-color:transparent;
}
:global(.borderless),
.borderless{
  border:none !important;
}
.border-bottom{
  border-bottom:1px dashed #eeecec;
}
:global(.border-medium){
  border-width:2px;
}
body,
div,
nav,
article,
section,
main,
header,
footer,
input,
form,
table,
fieldset,
textarea,
ul,
li,
span{
  box-sizing:border-box;
}
:global(.clearfix:before),
:global(.clearfix:after){
  content:" ";
  display:table;
}
:global(.clearfix:after){
  clear:both;
}
:global(.clearfix){
  *zoom:1;
}
:global(.text-default),
.text-default{
  color:#4c5773;
}
:global(.text-default-hover:hover){
  color:#4c5773;
}
:global(.text-brand),
.text-brand{
  color:var(--color-brand);
}
:global(.text-success){
  color:#84bb4c;
}
:global(.text-error),
.text-error,
:global(.text-error-hover:hover){
  color:#ed6e6e;
}
:global(.bg-error-hover:hover){
  background-color:#ed6e6e;
}
:global(.bg-error-input){
  background-color:#ffffff;
}
:global(.text-slate){
  color:#696e7b;
}
:global(.text-slate-light){
  color:#949aab;
}
:global(.text-light),
.text-light{
  color:#949aab;
}
:global(.text-medium),
.text-medium,
:global(.text-medium-hover:hover){
  color:#696e7b;
}
:global(.text-dark),
.text-dark,
:global(.text-dark-hover:hover){
  color:#4c5773;
}
:global(.bg-light),
:global(.bg-light-hover:hover){
  background-color:#f9fbfc;
}
:global(.bg-medium){
  background-color:#edf2f5;
}
:global(.text-white),
.text-white,
:global(.text-white-hover:hover){
  color:#ffffff;
}
:global(.bg-white),
.bg-white{
  background-color:#ffffff;
}
:global(.bg-light-blue){
  background-color:#f9fbfc;
}
:global(.cursor-pointer),
.cursor-pointer{
  cursor:pointer;
}
:global(.cursor-grab),
.cursor-grab{
  cursor:grab;
}
:global(.cursor-default),
.cursor-default{
  cursor:default;
}
:global(.flex),
.flex{
  display:flex;
}
:global(.inline-flex),
.inline-flex{
  display:inline-flex;
}
:global(.flex-auto),
.flex-auto{
  flex:auto;
}
:global(.flex-full),
.flex-full{
  flex:1 0 auto;
}
:global(.flex-half),
.flex-half{
  flex:0.5;
}
:global(.flex-3-quarters),
.flex-3-quarters{
  flex:0.75;
}
:global(.flex-1-quarter),
.flex-1-quarter{
  flex:0.25;
}
:global(.flex-no-shrink),
.flex-no-shrink{
  flex-shrink:0;
}
:global(.flex-basis-auto){
  flex-basis:auto;
}
:global(.flex-basis-none){
  flex-basis:0;
}
:global(.shrink-below-content-size){
  min-width:0;
  min-height:0;
}
:global(.align-center),
.align-center{
  align-items:center;
}
:global(.align-baseline),
.align-baseline{
  align-items:baseline;
}
:global(.justify-center),
.justify-center{
  justify-content:center;
}
:global(.justify-evenly){
  justify-content:space-evenly;
}
:global(.justify-between){
  justify-content:space-between;
}
:global(.justify-end){
  justify-content:flex-end;
}
:global(.align-start){
  align-items:flex-start;
}
:global(.align-end){
  align-items:flex-end;
}
:global(.align-stretch){
  align-items:stretch;
}
:global(.align-self-end),
.align-self-end{
  align-self:flex-end;
}
:global(.align-self-start),
.align-self-start{
  align-self:flex-start;
}
:global(.align-self-center){
  align-self:center;
}
:global(.align-self-stretch){
  align-self:stretch;
}
:global(.flex-align-right),
.flex-align-right{
  margin-left:auto;
}
@media screen and (min-width: 40em){
  :global(.sm-flex-align-right){
    margin-left:auto;
  }
}
@media screen and (min-width: 60em){
  :global(.md-flex-align-right){
    margin-left:auto;
  }
}
@media screen and (min-width: 80em){
  :global(.lg-flex-align-right){
    margin-left:auto;
  }
}
:global(.layout-centered),
.layout-centered{
  align-items:center;
  justify-content:center;
}
@media screen and (min-width: 40em){
  :global(.sm-layout-centered){
    align-items:center;
    justify-content:center;
  }
}
@media screen and (min-width: 60em){
  :global(.md-layout-centered){
    align-items:center;
    justify-content:center;
  }
}
@media screen and (min-width: 80em){
  :global(.lg-layout-centered){
    align-items:center;
    justify-content:center;
  }
}
:global(.flex-column){
  flex-direction:column;
}
:global(.flex-column-reverse){
  flex-direction:column-reverse;
}
@media screen and (min-width: 40em){
  :global(.sm-flex-column){
    flex-direction:column;
  }
}
@media screen and (min-width: 60em){
  :global(.md-flex-column){
    flex-direction:column;
  }
}
:global(.flex-row),
.flex-row{
  flex-direction:row;
}
@media screen and (min-width: 40em){
  :global(.sm-flex-row){
    flex-direction:row;
  }
}
:global(.flex-wrap){
  flex-wrap:wrap;
}
:global(.flex-reverse){
  flex-direction:row-reverse;
}
@media screen and (min-width: 40em){
  :global(.sm-flex-reverse){
    flex-direction:row-reverse;
  }
}
@media screen and (min-width: 60em){
  :global(.md-flex-reverse){
    flex-direction:row-reverse;
  }
}
@media screen and (min-width: 80em){
  :global(.lg-flex-reverse){
    flex-direction:row-reverse;
  }
}
@media screen and (min-width: 120em){
  :global(.xl-flex-reverse){
    flex-direction:row-reverse;
  }
}
:global(.no-flex){
  flex:0 1 0%;
}
@media screen and (min-width: 60em){
  :global(.md-no-flex){
    flex:0 !important;
  }
}
:global(.ie-wrap-content-fix){
  max-width:100%;
}
:global(.row-gap-1),
.row-gap-1{
  grid-row-gap:0.5rem;
  row-gap:0.5rem;
}
:global(.float-left),
.float-left{
  float:left;
}
:global(.float-right),
.float-right{
  float:right;
}
@font-face{
  font-family:"Lato";
  font-style:normal;
  font-weight:400;
  src:url("~fonts/Lato/lato-v16-latin-regular.eot");
  src:local("Lato Regular"), local("Lato-Regular"), url("~fonts/Lato/lato-v16-latin-regular.eot?#iefix") format("embedded-opentype"), url("~fonts/Lato/lato-v16-latin-regular.woff2") format("woff2"), url("~fonts/Lato/lato-v16-latin-regular.woff") format("woff"), url("~fonts/Lato/lato-v16-latin-regular.ttf") format("truetype"), url("~fonts/Lato/lato-v16-latin-regular.svg#Lato") format("svg");
}
@font-face{
  font-family:"Lato";
  font-style:normal;
  font-weight:700;
  src:url("~fonts/Lato/lato-v16-latin-700.eot");
  src:local("Lato Bold"), local("Lato-Bold"), url("~fonts/Lato/lato-v16-latin-700.eot?#iefix") format("embedded-opentype"), url("~fonts/Lato/lato-v16-latin-700.woff2") format("woff2"), url("~fonts/Lato/lato-v16-latin-700.woff") format("woff"), url("~fonts/Lato/lato-v16-latin-700.ttf") format("truetype"), url("~fonts/Lato/lato-v16-latin-700.svg#Lato") format("svg");
}
@font-face{
  font-family:"Lato";
  font-style:normal;
  font-weight:900;
  src:url("~fonts/Lato/lato-v16-latin-900.eot");
  src:local("Lato Black"), local("Lato-Black"), url("~fonts/Lato/lato-v16-latin-900.eot?#iefix") format("embedded-opentype"), url("~fonts/Lato/lato-v16-latin-900.woff2") format("woff2"), url("~fonts/Lato/lato-v16-latin-900.woff") format("woff"), url("~fonts/Lato/lato-v16-latin-900.ttf") format("truetype"), url("~fonts/Lato/lato-v16-latin-900.svg#Lato") format("svg");
}
@font-face{
  font-family:"PT Serif";
  src:local("PT Serif"), local("PTSerif-Regular"), url("~fonts/PT_Serif/PTSerif-Regular.woff2") format("woff2");
  font-weight:400;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"PT Serif";
  src:local("PT Serif Bold"), local("PTSerif-Bold"), url("~fonts/PT_Serif/PTSerif-Bold.woff2") format("woff2");
  font-weight:700;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Merriweather";
  src:local("Merriweather Regular"), local("Merriweather-Regular"), url("~fonts/Merriweather/Merriweather-Regular.woff2") format("woff2");
  font-weight:400;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Merriweather";
  src:local("Merriweather Bold"), local("Merriweather-Bold"), url("~fonts/Merriweather/Merriweather-Bold.woff2") format("woff2");
  font-weight:700;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Merriweather";
  src:local("Merriweather Black"), local("Merriweather-Black"), url("~fonts/Merriweather/Merriweather-Black.woff2") format("woff2");
  font-weight:900;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Montserrat";
  src:local("Montserrat Regular"), local("Montserrat-Regular"), url("~fonts/Montserrat/Montserrat-Regular.woff2") format("woff2");
  font-weight:normal;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Montserrat";
  src:local("Montserrat Bold"), local("Montserrat-Bold"), url("~fonts/Montserrat/Montserrat-Bold.woff2") format("woff2");
  font-weight:bold;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Montserrat";
  src:local("Montserrat Black"), local("Montserrat-Black"), url("~fonts/Montserrat/Montserrat-Black.woff2") format("woff2");
  font-weight:900;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Open Sans";
  src:local("Open Sans Regular"), local("OpenSans-Regular"), url("~fonts/Open_Sans/OpenSans-Regular.woff2") format("woff2");
  font-weight:normal;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Open Sans";
  src:local("Open Sans Bold"), local("OpenSans-Bold"), url("~fonts/Open_Sans/OpenSans-Bold.woff2") format("woff2");
  font-weight:bold;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Oswald";
  src:local("Oswald Bold"), local("Oswald-Bold"), url("~fonts/Oswald/Oswald-Bold.woff2") format("woff2");
  font-weight:bold;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Oswald";
  src:local("Oswald Regular"), local("Oswald-Regular"), url("~fonts/Oswald/Oswald-Regular.woff2") format("woff2");
  font-weight:normal;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Raleway";
  src:local("Raleway Bold"), local("Raleway-Bold"), url("~fonts/Raleway/Raleway-Bold.woff2") format("woff2");
  font-weight:bold;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Raleway";
  src:local("Raleway Regular"), local("Raleway-Regular"), url("~fonts/Raleway/Raleway-Regular.woff2") format("woff2");
  font-weight:normal;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Raleway";
  src:local("Raleway Black"), local("Raleway-Black"), url("~fonts/Raleway/Raleway-Black.woff2") format("woff2");
  font-weight:900;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Roboto";
  src:local("Roboto Black"), local("Roboto-Black"), url("~fonts/Roboto/Roboto-Black.woff2") format("woff2");
  font-weight:900;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Roboto";
  src:local("Roboto Bold"), local("Roboto-Bold"), url("~fonts/Roboto/Roboto-Bold.woff2") format("woff2");
  font-weight:bold;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Roboto";
  src:local("Roboto"), local("Roboto-Regular"), url("~fonts/Roboto/Roboto-Regular.woff2") format("woff2");
  font-weight:normal;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Roboto Condensed";
  src:local("Roboto Condensed Bold"), local("RobotoCondensed-Bold"), url("~fonts/Roboto_Condensed/RobotoCondensed-Bold.woff2") format("woff2");
  font-weight:bold;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Roboto Condensed";
  src:local("Roboto Condensed"), local("RobotoCondensed-Regular"), url("~fonts/Roboto_Condensed/RobotoCondensed-Regular.woff2") format("woff2");
  font-weight:normal;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Slabo 27px";
  src:local("Slabo 27px"), local("Slabo27px-Regular"), url("~fonts/Slabo_27px/Slabo27px-Regular.woff2") format("woff2");
  font-weight:normal;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Source Sans Pro";
  src:local("Source Sans Pro Black"), local("SourceSansPro-Black"), url("~fonts/Source_Sans_Pro/SourceSansPro-Black.woff2") format("woff2");
  font-weight:900;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Source Sans Pro";
  src:local("Source Sans Pro"), local("SourceSansPro-Regular"), url("~fonts/Source_Sans_Pro/SourceSansPro-Regular.woff2") format("woff2");
  font-weight:normal;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Source Sans Pro";
  src:local("Source Sans Pro Bold"), local("SourceSansPro-Bold"), url("~fonts/Source_Sans_Pro/SourceSansPro-Bold.woff2") format("woff2");
  font-weight:bold;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Lora";
  src:local("Lora Bold"), local("Lora-Bold"), url("~fonts/Lora/Lora-Bold.woff2") format("woff2");
  font-weight:bold;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Lora";
  src:local("Lora Regular"), local("Lora-Regular"), url("~fonts/Lora/Lora-Regular.woff2") format("woff2");
  font-weight:normal;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Noto Sans";
  src:local("Noto Sans Black"), local("NotoSans-Black"), url("~fonts/Noto_Sans/NotoSans-Black.woff2") format("woff2");
  font-weight:900;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Noto Sans";
  src:local("Noto Sans Regular"), local("NotoSans-Regular"), url("~fonts/Noto_Sans/NotoSans-Regular.woff2") format("woff2");
  font-weight:normal;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Noto Sans";
  src:local("Noto Sans Bold"), local("NotoSans-Bold"), url("~fonts/Noto_Sans/NotoSans-Bold.woff2") format("woff2");
  font-weight:bold;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Playfair Display";
  src:local("Playfair Display Regular"), local("PlayfairDisplay-Regular"), url("~fonts/Playfair_Display/PlayfairDisplay-Regular.woff2") format("woff2");
  font-weight:normal;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Playfair Display";
  src:local("Playfair Display Bold"), local("PlayfairDisplay-Bold"), url("~fonts/Playfair_Display/PlayfairDisplay-Bold.woff2") format("woff2");
  font-weight:bold;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Playfair Display";
  src:local("Playfair Display Black"), local("PlayfairDisplay-Black"), url("~fonts/Playfair_Display/PlayfairDisplay-Black.woff2") format("woff2");
  font-weight:900;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Poppins";
  src:local("Poppins Bold"), local("Poppins-Bold"), url("~fonts/Poppins/Poppins-Bold.woff2") format("woff2");
  font-weight:bold;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Poppins";
  src:local("Poppins Regular"), local("Poppins-Regular"), url("~fonts/Poppins/Poppins-Regular.woff2") format("woff2");
  font-weight:normal;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Poppins";
  src:local("Poppins Black"), local("Poppins-Black"), url("~fonts/Poppins/Poppins-Black.woff2") format("woff2");
  font-weight:900;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"PT Sans";
  src:local("PT Sans"), local("PTSans-Regular"), url("~fonts/PT_Sans/PTSans-Regular.woff2") format("woff2");
  font-weight:normal;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"PT Sans";
  src:local("PT Sans Bold"), local("PTSans-Bold"), url("~fonts/PT_Sans/PTSans-Bold.woff2") format("woff2");
  font-weight:bold;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Roboto Mono";
  src:local("Roboto Mono Bold"), local("RobotoMono-Bold"), url("~fonts/Roboto_Mono/RobotoMono-Bold.woff2") format("woff2");
  font-weight:bold;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Roboto Mono";
  src:local("Roboto Mono Regular"), local("RobotoMono-Regular"), url("~fonts/Roboto_Mono/RobotoMono-Regular.woff2") format("woff2");
  font-weight:normal;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Roboto Slab";
  src:local("Roboto Slab Black"), local("RobotoSlab-Black"), url("~fonts/Roboto_Slab/RobotoSlab-Black.woff2") format("woff2");
  font-weight:900;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Roboto Slab";
  src:local("Roboto Slab Regular"), local("RobotoSlab-Regular"), url("~fonts/Roboto_Slab/RobotoSlab-Regular.woff2") format("woff2");
  font-weight:normal;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Roboto Slab";
  src:local("Roboto Slab Bold"), local("RobotoSlab-Bold"), url("~fonts/Roboto_Slab/RobotoSlab-Bold.woff2") format("woff2");
  font-weight:bold;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Ubuntu";
  src:local("Ubuntu Bold"), local("Ubuntu-Bold"), url("~fonts/Ubuntu/Ubuntu-Bold.woff2") format("woff2");
  font-weight:bold;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Ubuntu";
  src:local("Ubuntu Regular"), local("Ubuntu-Regular"), url("~fonts/Ubuntu/Ubuntu-Regular.woff2") format("woff2");
  font-weight:normal;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Inter";
  src:local("Inter Regular"), local("Inter-Regular"), url("~fonts/Inter/Inter-Regular.woff2") format("woff2");
  font-weight:normal;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Inter";
  src:local("Inter Bold"), local("Inter-Bold"), url("~fonts/Inter/Inter-Bold.woff2") format("woff2");
  font-weight:bold;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family:"Inter";
  src:local("Inter Black"), local("Inter-Black"), url("~fonts/Inter/Inter-Black.woff2") format("woff2");
  font-weight:900;
  font-style:normal;
  font-display:swap;
}
:global(.undefined){
  border:1px solid red !important;
}
:global(.Grid){
  display:flex;
  flex-wrap:wrap;
  list-style:none;
  margin:0;
  padding:0;
}
:global(.Grid-cell){
  flex:1;
}
:global(.Grid--1of2 > .Grid-cell){
  flex:0 0 50%;
}
@media (min-width: 40em){
  :global(.small-Grid--1of2 > .Grid-cell){
    flex:0 0 50%;
  }
}
@media (min-width: 60em){
  :global(.md-Grid--1of2 > .Grid-cell){
    flex:0 0 50%;
  }
}
@media (min-width: 80em){
  :global(.large-Grid--1of2 > .Grid-cell){
    flex:0 0 50%;
  }
}
:global(.Grid--gutters){
  margin:-1em 0 1em -1em;
}
:global(.Grid--gutters > .Grid-cell){
  padding:1em 0 0 1em;
}
h1,
:global(.h1),
h2,
:global(.h2),
h3,
:global(.h3),
h4,
:global(.h4),
h5,
:global(.h5),
h6,
:global(.h6){
  font-weight:700;
  margin-top:0;
  margin-bottom:0;
}
h1{
  font-size:2em;
}
:global(.h1){
  font-size:2em;
}
:global(.h2){
  font-size:1.5em;
}
:global(.h3){
  font-size:1.17em;
}
:global(.h4){
  font-size:1.12em;
}
:global(.h5){
  font-size:0.83em;
}
:global(.h6){
  font-size:0.75em;
}
@media screen and (min-width: 40em){
  :global(.sm-h1){
    font-size:2em;
  }
  :global(.sm-h2){
    font-size:1.5em;
  }
  :global(.sm-h3){
    font-size:1.17em;
  }
  :global(.sm-h4){
    font-size:1.12em;
  }
  :global(.sm-h5){
    font-size:0.83em;
  }
  :global(.sm-h6){
    font-size:0.75em;
  }
}
@media screen and (min-width: 60em){
  :global(.md-h1){
    font-size:2em;
  }
  :global(.md-h2){
    font-size:1.5em;
  }
  :global(.md-h3){
    font-size:1.17em;
  }
  :global(.md-h4){
    font-size:1.12em;
  }
  :global(.md-h5){
    font-size:0.83em;
  }
  :global(.md-h6){
    font-size:0.75em;
  }
}
@media screen and (min-width: 80em){
  :global(.lg-h1){
    font-size:2em;
  }
  :global(.lg-h2){
    font-size:1.5em;
  }
  :global(.lg-h3){
    font-size:1.17em;
  }
  :global(.lg-h4){
    font-size:1.12em;
  }
  :global(.lg-h5){
    font-size:0.83em;
  }
  :global(.lg-h6){
    font-size:0.75em;
  }
}
:global(.hide){
  display:none !important;
}
:global(.show){
  display:inherit;
}
:global(.hidden){
  visibility:hidden;
}
:global(.sm-show),
:global(.md-show),
:global(.lg-show),
:global(.xl-show){
  display:none;
}
@media screen and (min-width: 23em){
  :global(.xs-hide){
    display:none !important;
  }
}
@media screen and (min-width: 23em){
  :global(.xs-show){
    display:inherit !important;
  }
}
@media screen and (min-width: 40em){
  :global(.sm-hide){
    display:none !important;
  }
}
@media screen and (min-width: 40em){
  :global(.sm-show){
    display:inherit !important;
  }
}
@media screen and (min-width: 60em){
  :global(.md-hide){
    display:none !important;
  }
}
@media screen and (min-width: 60em){
  :global(.md-show){
    display:inherit !important;
  }
}
@media screen and (min-width: 80em){
  :global(.lg-hide){
    display:none !important;
  }
}
@media screen and (min-width: 80em){
  :global(.lg-show){
    display:inherit !important;
  }
}
@media screen and (--breakpoint-min-xl) h{
  :global(.xl-hide){
    display:none !important;
  }
}
@media screen and (min-width: 120em){
  :global(.xl-show){
    display:inherit !important;
  }
}
:global(.hover-parent.hover--display .hover-child),
:global(.hover-parent:hover.hover--display .hover-child--hidden){
  display:none;
}
:global(.hover-parent:hover.hover--display .hover-child){
  display:block;
}
:global(.hover-parent.hover--visibility .hover-child),
:global(.hover-parent:hover.hover--visibility .hover-child--hidden){
  visibility:hidden;
}
:global(.hover-parent:hover.hover--visibility .hover-child){
  visibility:visible;
}
:global(.hover-parent:hover.hover--inherit > *),
:global(.hover-parent:hover.hover--inherit .hover-child){
  color:inherit !important;
}
:global(.hover-child--smooth){
  transition:opacity 0.2s ease-in-out;
}
@media (prefers-reduced-motion){
  :global(.hover-child--smooth){
    transition:none;
  }
}
:global(.hover-parent.hover--display .hover-child--smooth),
:global(.hover-parent.hover--visibility .hover-child--smooth){
  opacity:0;
}
:global(.hover-parent:hover.hover--display .hover-child--smooth),
:global(.hover-parent:hover.hover--visibility .hover-child--smooth){
  opacity:1;
}
:global(.input),
.input{
  color:#4c5773;
  font-size:1.12em;
  padding:0.75rem 0.75rem;
  border:1px solid #eeecec;
  border-radius:8px;
  transition:border 0.3s linear;
}
:global(.input::-ms-clear){
  display:none;
  width:0;
  height:0;
}
:global(.input:focus),
.input:focus:focus{
  outline:none;
  border:1px solid var(--color-brand);
  transition:border 0.3s linear;
  color:#4c5773;
}
:global(.input--borderless),
:global(.input--borderless:focus){
  box-shadow:none;
  outline:0;
  border:none !important;
  background:transparent;
}
:global(.input:disabled){
  opacity:0.5;
  cursor:not-allowed;
}
:global(.no-focus:focus){
  outline:0;
}
:global(.input[type="search"]){
  -webkit-appearance:none;
}
:global(.wrapper),
.wrapper{
  width:100%;
  margin:0 auto;
  padding:0 1em;
}
@media screen and (min-width: 40em){
  :global(.wrapper),
  .wrapper{
    padding-left:2em;
    padding-right:2em;
  }
}
@media screen and (min-width: 60em){
  :global(.wrapper),
  .wrapper{
    padding-left:3em;
    padding-right:3em;
  }
}
:global(.full-height),
.full-height{
  height:100%;
}
:global(.block),
.block{
  display:block;
}
:global(.inline),
.inline{
  display:inline;
}
:global(.inline-block),
.inline-block{
  display:inline-block;
}
:global(.table){
  display:table;
}
:global(.full),
:global(.full-width),
.full,
.full-width{
  width:100%;
}
:global(.half){
  width:50%;
}
:global(.fixed),
.fixed{
  position:fixed;
}
:global(.relative),
.relative{
  position:relative;
}
:global(.absolute),
.absolute{
  position:absolute;
}
:global(.top),
.top{
  top:0;
}
:global(.right),
.right{
  right:0;
}
:global(.bottom),
.bottom{
  bottom:0;
}
:global(.left),
.left{
  left:0;
}
@media screen and (min-width: 60em){
  :global(.wrapper.wrapper--trim),
  .wrapper.wrapper--trim{
    max-width:940px;
  }
}
:global(.spread),
.spread{
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
}
:global(.stacking-context){
  transform:scale(1);
}
:global(.no-decoration),
.no-decoration{
  text-decoration:none;
}
:global(.link){
  cursor:pointer;
  text-decoration:none;
  color:var(--color-brand);
}
:global(.link:hover){
  text-decoration:underline;
}
:global(.link:focus){
  outline:2px solid var(--color-focus);
}
:global(.link:focus:not(:focus-visible)){
  outline:none;
}
:global(.link--wrappable){
  word-break:break-all;
}
:global(.overflow-auto){
  overflow:auto;
}
:global(.overflow-hidden){
  overflow:hidden;
}
:global(.overflow-x-scroll){
  overflow-x:scroll;
}
:global(.overflow-y-scroll){
  overflow-y:scroll;
}
:global(.rounded),
.rounded{
  border-radius:8px;
}
:global(.circular){
  border-radius:99px !important;
}
:global(.scroll-y),
.scroll-y{
  overflow-y:auto;
}
:global(.scroll-x){
  overflow-x:auto;
}
:global(.scroll-show::-webkit-scrollbar){
  width:15px;
  min-height:10px;
}
:global(.scroll-show--hover::-webkit-scrollbar){
  display:none;
}
:global(.scroll-show--hover:hover::-webkit-scrollbar){
  display:inherit;
}
:global(.scroll-show::-webkit-scrollbar-thumb){
  border:4px solid transparent;
  border-radius:7px;
  background-clip:padding-box;
  background-color:#edf2f5;
}
:global(.scroll-show::-webkit-scrollbar-button){
  width:0;
  height:0;
  display:none;
}
:global(.scroll-show::-webkit-scrollbar-corner){
  background-color:transparent;
}
:global(.scroll-show:hover::-webkit-scrollbar-thumb){
  background-color:#93a1ab;
}
:global(.scroll-show::-webkit-scrollbar-thumb:horizontal:hover),
:global(.scroll-show::-webkit-scrollbar-thumb:vertical:hover){
  background-color:#93a1ab;
}
:global(.scroll-show::-webkit-scrollbar-thumb:horizontal:active),
:global(.scroll-show::-webkit-scrollbar-thumb:vertical:active){
  background-color:#93a1ab;
}
:global(.scroll-show.scroll--light::-webkit-scrollbar-thumb){
  border-radius:0;
  background-color:#edf2f5;
}
:global(.scroll-show.scroll--light::-webkit-scrollbar-thumb:horizontal:hover),
:global(.scroll-show.scroll--light::-webkit-scrollbar-thumb:vertical:hover),
:global(.scroll-show.scroll--light::-webkit-scrollbar-thumb:horizontal:active),
:global(.scroll-show.scroll--light::-webkit-scrollbar-thumb:vertical:active){
  background-color:#edf2f5;
}
:global(.scroll-hide){
  -ms-overflow-style:none;
  overflow:-moz-scrollbars-none;
}
:global(.scroll-hide::-webkit-scrollbar){
  display:none;
}
:global(.scroll-hide-all),
:global(.scroll-hide-all *){
  -ms-overflow-style:none;
  overflow:-moz-scrollbars-none;
  scrollbar-width:none;
}
:global(.scroll-hide-all::-webkit-scrollbar),
:global(.scroll-hide-all *::-webkit-scrollbar){
  display:none;
}
:global(.shadowed),
.shadowed{
  box-shadow:0 2px 2px rgba(0, 0, 0, 0.13);
}
:global(.ml-auto),
.ml-auto{
  margin-left:auto;
}
:global(.mr-auto),
.mr-auto{
  margin-right:auto;
}
:global(.mt-auto){
  margin-top:auto;
}
:global(.mb-auto){
  margin-bottom:auto;
}
:global(.p0),
.p0{
  padding:0;
}
:global(.pt0),
.pt0{
  padding-top:0;
}
:global(.pb0),
.pb0{
  padding-bottom:0;
}
:global(.pl0),
.pl0{
  padding-left:0;
}
:global(.pr0),
.pr0{
  padding-right:0;
}
:global(.p1),
.p1{
  padding:0.5rem;
}
:global(.px1),
.px1{
  padding-left:0.5rem;
  padding-right:0.5rem;
}
:global(.py1),
.py1{
  padding-top:0.5rem;
  padding-bottom:0.5rem;
}
:global(.pt1),
.pt1{
  padding-top:0.5rem;
}
:global(.pb1),
.pb1{
  padding-bottom:0.5rem;
}
:global(.pl1),
.pl1{
  padding-left:0.5rem;
}
:global(.pr1),
.pr1{
  padding-right:0.5rem;
}
:global(.p2),
.p2{
  padding:1rem;
}
:global(.px2),
.px2{
  padding-left:1rem;
  padding-right:1rem;
}
:global(.py2),
.py2{
  padding-top:1rem;
  padding-bottom:1rem;
}
:global(.pt2),
.pt2{
  padding-top:1rem;
}
:global(.pb2),
.pb2{
  padding-bottom:1rem;
}
:global(.pl2),
.pl2{
  padding-left:1rem;
}
:global(.pr2),
.pr2{
  padding-right:1rem;
}
:global(.p3),
.p3{
  padding:1.5rem;
}
:global(.px3),
.px3{
  padding-left:1.5rem;
  padding-right:1.5rem;
}
:global(.py3),
.py3{
  padding-top:1.5rem;
  padding-bottom:1.5rem;
}
:global(.pt3),
.pt3{
  padding-top:1.5rem;
}
:global(.pb3),
.pb3{
  padding-bottom:1.5rem;
}
:global(.pl3),
.pl3{
  padding-left:1.5rem;
}
:global(.pr3),
.pr3{
  padding-right:1.5rem;
}
:global(.p4),
.p4{
  padding:2rem;
}
:global(.px4),
.px4{
  padding-left:2rem;
  padding-right:2rem;
}
:global(.py4),
.py4{
  padding-top:2rem;
  padding-bottom:2rem;
}
:global(.pt4),
.pt4{
  padding-top:2rem;
}
:global(.pb4),
.pb4{
  padding-bottom:2rem;
}
:global(.pl4),
.pl4{
  padding-left:2rem;
}
:global(.pr4),
.pr4{
  padding-right:2rem;
}
:global(.m0),
.m0{
  margin:0;
}
:global(.mt0),
.mt0{
  margin-top:0;
}
:global(.mb0),
.mb0{
  margin-bottom:0;
}
:global(.ml0),
.ml0{
  margin-left:0;
}
:global(.mr0),
.mr0{
  margin-right:0;
}
:global(.m1),
.m1{
  margin:0.5rem;
}
:global(.mx1),
.mx1{
  margin-left:0.5rem;
  margin-right:0.5rem;
}
:global(.my1),
.my1{
  margin-top:0.5rem;
  margin-bottom:0.5rem;
}
:global(.mt1),
.mt1{
  margin-top:0.5rem;
}
:global(.mb1),
.mb1{
  margin-bottom:0.5rem;
}
:global(.ml1),
.ml1{
  margin-left:0.5rem;
}
:global(.mr1),
.mr1{
  margin-right:0.5rem;
}
:global(.m2),
.m2{
  margin:1rem;
}
:global(.mx2),
.mx2{
  margin-left:1rem;
  margin-right:1rem;
}
:global(.my2),
.my2{
  margin-top:1rem;
  margin-bottom:1rem;
}
:global(.mt2),
.mt2{
  margin-top:1rem;
}
:global(.mb2),
.mb2{
  margin-bottom:1rem;
}
:global(.ml2),
.ml2{
  margin-left:1rem;
}
:global(.mr2),
.mr2{
  margin-right:1rem;
}
:global(.m3),
.m3{
  margin:1.5rem;
}
:global(.mx3),
.mx3{
  margin-left:1.5rem;
  margin-right:1.5rem;
}
:global(.my3),
.my3{
  margin-top:1.5rem;
  margin-bottom:1.5rem;
}
:global(.mt3),
.mt3{
  margin-top:1.5rem;
}
:global(.mb3),
.mb3{
  margin-bottom:1.5rem;
}
:global(.ml3),
.ml3{
  margin-left:1.5rem;
}
:global(.mr3),
.mr3{
  margin-right:1.5rem;
}
:global(.m4),
.m4{
  margin:2rem;
}
:global(.mx4),
.mx4{
  margin-left:2rem;
  margin-right:2rem;
}
:global(.my4),
.my4{
  margin-top:2rem;
  margin-bottom:2rem;
}
:global(.mt4),
.mt4{
  margin-top:2rem;
}
:global(.mb4),
.mb4{
  margin-bottom:2rem;
}
:global(.ml4),
.ml4{
  margin-left:2rem;
}
:global(.mr4),
.mr4{
  margin-right:2rem;
}
:global(.mln1){
  margin-left:calc(-1 * 0.5rem);
}
:global(.mln2){
  margin-left:calc(-1 * 1rem);
}
:global(.mln3){
  margin-left:calc(-1 * 1.5rem);
}
:global(.mln4){
  margin-left:calc(-1 * 2rem);
}
:global(.mbn1){
  margin-bottom:calc(-1 * 0.5rem);
}
:global(.mbn2){
  margin-bottom:calc(-1 * 1rem);
}
:global(.mbn3){
  margin-bottom:calc(-1 * 1.5rem);
}
:global(.mbn4){
  margin-bottom:calc(-1 * 2rem);
}
@media screen and (min-width: 40em){
  :global(.sm-p0){
    padding:0;
  }
  :global(.sm-pt0){
    padding-top:0;
  }
  :global(.sm-pb0){
    padding-bottom:0;
  }
  :global(.sm-pl0){
    padding-left:0;
  }
  :global(.sm-pr0){
    padding-right:0;
  }
  :global(.sm-p1){
    padding:0.5rem;
  }

  :global(.sm-px1){
    padding-left:0.5rem;
    padding-right:0.5rem;
  }

  :global(.sm-py1){
    padding-top:0.5rem;
    padding-bottom:0.5rem;
  }

  :global(.sm-pt1){
    padding-top:0.5rem;
  }
  :global(.sm-pb1){
    padding-bottom:0.5rem;
  }
  :global(.sm-pl1){
    padding-left:0.5rem;
  }
  :global(.sm-pr1){
    padding-right:0.5rem;
  }

  :global(.sm-p2){
    padding:1rem;
  }

  :global(.sm-px2){
    padding-left:1rem;
    padding-right:1rem;
  }

  :global(.sm-py2){
    padding-top:1rem;
    padding-bottom:1rem;
  }

  :global(.sm-pt2){
    padding-top:1rem;
  }
  :global(.sm-pb2){
    padding-bottom:1rem;
  }
  :global(.sm-pl2){
    padding-left:1rem;
  }
  :global(.sm-pr2){
    padding-right:1rem;
  }

  :global(.sm-p3){
    padding:1.5rem;
  }

  :global(.sm-px3){
    padding-left:1.5rem;
    padding-right:1.5rem;
  }

  :global(.sm-py3){
    padding-top:1.5rem;
    padding-bottom:1.5rem;
  }

  :global(.sm-pt3){
    padding-top:1.5rem;
  }
  :global(.sm-pb3){
    padding-bottom:1.5rem;
  }
  :global(.sm-pl3){
    padding-left:1.5rem;
  }
  :global(.sm-pr3){
    padding-right:1.5rem;
  }

  :global(.sm-p4){
    padding:2rem;
  }

  :global(.sm-px4){
    padding-left:2rem;
    padding-right:2rem;
  }

  :global(.sm-py4){
    padding-top:2rem;
    padding-bottom:2rem;
  }

  :global(.sm-pt4){
    padding-top:2rem;
  }
  :global(.sm-pb4){
    padding-bottom:2rem;
  }
  :global(.sm-pl4){
    padding-left:2rem;
  }
  :global(.sm-pr4){
    padding-right:2rem;
  }
  :global(.sm-m0){
    margin:0;
  }
  :global(.sm-mt0){
    margin-top:0;
  }
  :global(.sm-mb0){
    margin-bottom:0;
  }
  :global(.sm-ml0){
    margin-left:0;
  }
  :global(.sm-mr0){
    margin-right:0;
  }
  :global(.sm-m1){
    margin:0.5rem;
  }

  :global(.sm-mx1){
    margin-left:0.5rem;
    margin-right:0.5rem;
  }

  :global(.sm-my1){
    margin-top:0.5rem;
    margin-bottom:0.5rem;
  }

  :global(.sm-mt1){
    margin-top:0.5rem;
  }
  :global(.sm-mb1){
    margin-bottom:0.5rem;
  }
  :global(.sm-ml1){
    margin-left:0.5rem;
  }
  :global(.sm-mr1){
    margin-right:0.5rem;
  }

  :global(.sm-m2){
    margin:1rem;
  }

  :global(.sm-mx2){
    margin-left:1rem;
    margin-right:1rem;
  }

  :global(.sm-my2){
    margin-top:1rem;
    margin-bottom:1rem;
  }

  :global(.sm-mt2){
    margin-top:1rem;
  }
  :global(.sm-mb2){
    margin-bottom:1rem;
  }
  :global(.sm-ml2){
    margin-left:1rem;
  }
  :global(.sm-mr2){
    margin-right:1rem;
  }

  :global(.sm-m3){
    margin:1.5rem;
  }

  :global(.sm-mx3){
    margin-left:1.5rem;
    margin-right:1.5rem;
  }

  :global(.sm-my3){
    margin-top:1.5rem;
    margin-bottom:1.5rem;
  }

  :global(.sm-mt3){
    margin-top:1.5rem;
  }
  :global(.sm-mb3){
    margin-bottom:1.5rem;
  }
  :global(.sm-ml3){
    margin-left:1.5rem;
  }
  :global(.sm-mr3){
    margin-right:1.5rem;
  }

  :global(.sm-m4){
    margin:2rem;
  }

  :global(.sm-mx4){
    margin-left:2rem;
    margin-right:2rem;
  }

  :global(.sm-my4){
    margin-top:2rem;
    margin-bottom:2rem;
  }

  :global(.sm-mt4){
    margin-top:2rem;
  }
  :global(.sm-mb4){
    margin-bottom:2rem;
  }
  :global(.sm-ml4){
    margin-left:2rem;
  }
  :global(.sm-mr4){
    margin-right:2rem;
  }
}
@media screen and (min-width: 60em){
  :global(.md-p0){
    padding:0;
  }
  :global(.md-pt0){
    padding-top:0;
  }
  :global(.md-pb0){
    padding-bottom:0;
  }
  :global(.md-pl0){
    padding-left:0;
  }
  :global(.md-pr0){
    padding-right:0;
  }
  :global(.md-p1){
    padding:0.5rem;
  }

  :global(.md-px1){
    padding-left:0.5rem;
    padding-right:0.5rem;
  }

  :global(.md-py1){
    padding-top:0.5rem;
    padding-bottom:0.5rem;
  }

  :global(.md-pt1){
    padding-top:0.5rem;
  }
  :global(.md-pb1){
    padding-bottom:0.5rem;
  }
  :global(.md-pl1){
    padding-left:0.5rem;
  }
  :global(.md-pr1){
    padding-right:0.5rem;
  }

  :global(.md-p2){
    padding:1rem;
  }

  :global(.md-px2){
    padding-left:1rem;
    padding-right:1rem;
  }

  :global(.md-py2){
    padding-top:1rem;
    padding-bottom:1rem;
  }

  :global(.md-pt2){
    padding-top:1rem;
  }
  :global(.md-pb2){
    padding-bottom:1rem;
  }
  :global(.md-pl2){
    padding-left:1rem;
  }
  :global(.md-pr2){
    padding-right:1rem;
  }

  :global(.md-p3){
    padding:1.5rem;
  }

  :global(.md-px3){
    padding-left:1.5rem;
    padding-right:1.5rem;
  }

  :global(.md-py3){
    padding-top:1.5rem;
    padding-bottom:1.5rem;
  }

  :global(.md-pt3){
    padding-top:1.5rem;
  }
  :global(.md-pb3){
    padding-bottom:1.5rem;
  }
  :global(.md-pl3){
    padding-left:1.5rem;
  }
  :global(.md-pr3){
    padding-right:1.5rem;
  }

  :global(.md-p4){
    padding:2rem;
  }

  :global(.md-px4){
    padding-left:2rem;
    padding-right:2rem;
  }

  :global(.md-py4){
    padding-top:2rem;
    padding-bottom:2rem;
  }

  :global(.md-pt4){
    padding-top:2rem;
  }
  :global(.md-pb4){
    padding-bottom:2rem;
  }
  :global(.md-pl4){
    padding-left:2rem;
  }
  :global(.md-pr4){
    padding-right:2rem;
  }
  :global(.md-m0){
    margin:0;
  }
  :global(.md-mt0){
    margin-top:0;
  }
  :global(.md-mb0){
    margin-bottom:0;
  }
  :global(.md-ml0){
    margin-left:0;
  }
  :global(.md-mr0){
    margin-right:0;
  }
  :global(.md-m1){
    margin:0.5rem;
  }

  :global(.md-mx1){
    margin-left:0.5rem;
    margin-right:0.5rem;
  }

  :global(.md-my1){
    margin-top:0.5rem;
    margin-bottom:0.5rem;
  }

  :global(.md-mt1){
    margin-top:0.5rem;
  }
  :global(.md-mb1){
    margin-bottom:0.5rem;
  }
  :global(.md-ml1){
    margin-left:0.5rem;
  }
  :global(.md-mr1){
    margin-right:0.5rem;
  }

  :global(.md-m2){
    margin:1rem;
  }

  :global(.md-mx2){
    margin-left:1rem;
    margin-right:1rem;
  }

  :global(.md-my2){
    margin-top:1rem;
    margin-bottom:1rem;
  }

  :global(.md-mt2){
    margin-top:1rem;
  }
  :global(.md-mb2){
    margin-bottom:1rem;
  }
  :global(.md-ml2){
    margin-left:1rem;
  }
  :global(.md-mr2){
    margin-right:1rem;
  }

  :global(.md-m3){
    margin:1.5rem;
  }

  :global(.md-mx3){
    margin-left:1.5rem;
    margin-right:1.5rem;
  }

  :global(.md-my3){
    margin-top:1.5rem;
    margin-bottom:1.5rem;
  }

  :global(.md-mt3){
    margin-top:1.5rem;
  }
  :global(.md-mb3){
    margin-bottom:1.5rem;
  }
  :global(.md-ml3){
    margin-left:1.5rem;
  }
  :global(.md-mr3){
    margin-right:1.5rem;
  }

  :global(.md-m4){
    margin:2rem;
  }

  :global(.md-mx4){
    margin-left:2rem;
    margin-right:2rem;
  }

  :global(.md-my4){
    margin-top:2rem;
    margin-bottom:2rem;
  }

  :global(.md-mt4){
    margin-top:2rem;
  }
  :global(.md-mb4){
    margin-bottom:2rem;
  }
  :global(.md-ml4){
    margin-left:2rem;
  }
  :global(.md-mr4){
    margin-right:2rem;
  }
}
@media screen and (min-width: 80em){
  :global(.lg-p0){
    padding:0;
  }
  :global(.lg-pt0){
    padding-top:0;
  }
  :global(.lg-pb0){
    padding-bottom:0;
  }
  :global(.lg-pl0){
    padding-left:0;
  }
  :global(.lg-pr0){
    padding-right:0;
  }
  :global(.lg-p1){
    padding:0.5rem;
  }

  :global(.lg-px1){
    padding-left:0.5rem;
    padding-right:0.5rem;
  }

  :global(.lg-py1){
    padding-top:0.5rem;
    padding-bottom:0.5rem;
  }

  :global(.lg-pt1){
    padding-top:0.5rem;
  }
  :global(.lg-pb1){
    padding-bottom:0.5rem;
  }
  :global(.lg-pl1){
    padding-left:0.5rem;
  }
  :global(.lg-pr1){
    padding-right:0.5rem;
  }

  :global(.lg-p2){
    padding:1rem;
  }

  :global(.lg-px2){
    padding-left:1rem;
    padding-right:1rem;
  }

  :global(.lg-py2){
    padding-top:1rem;
    padding-bottom:1rem;
  }

  :global(.lg-pt2){
    padding-top:1rem;
  }
  :global(.lg-pb2){
    padding-bottom:1rem;
  }
  :global(.lg-pl2){
    padding-left:1rem;
  }
  :global(.lg-pr2){
    padding-right:1rem;
  }

  :global(.lg-p3){
    padding:1.5rem;
  }

  :global(.lg-px3){
    padding-left:1.5rem;
    padding-right:1.5rem;
  }

  :global(.lg-py3){
    padding-top:1.5rem;
    padding-bottom:1.5rem;
  }

  :global(.lg-pt3){
    padding-top:1.5rem;
  }
  :global(.lg-pb3){
    padding-bottom:1.5rem;
  }
  :global(.lg-pl3){
    padding-left:1.5rem;
  }
  :global(.lg-pr3){
    padding-right:1.5rem;
  }

  :global(.lg-p4){
    padding:2rem;
  }

  :global(.lg-px4){
    padding-left:2rem;
    padding-right:2rem;
  }

  :global(.lg-py4){
    padding-top:2rem;
    padding-bottom:2rem;
  }

  :global(.lg-pt4){
    padding-top:2rem;
  }
  :global(.lg-pb4){
    padding-bottom:2rem;
  }
  :global(.lg-pl4){
    padding-left:2rem;
  }
  :global(.lg-pr4){
    padding-right:2rem;
  }
  :global(.lg-m0){
    margin:0;
  }
  :global(.lg-mt0){
    margin-top:0;
  }
  :global(.lg-mb0){
    margin-bottom:0;
  }
  :global(.lg-ml0){
    margin-left:0;
  }
  :global(.lg-mr0){
    margin-right:0;
  }
  :global(.lg-m1){
    margin:0.5rem;
  }

  :global(.lg-mx1){
    margin-left:0.5rem;
    margin-right:0.5rem;
  }

  :global(.lg-my1){
    margin-top:0.5rem;
    margin-bottom:0.5rem;
  }

  :global(.lg-mt1){
    margin-top:0.5rem;
  }
  :global(.lg-mb1){
    margin-bottom:0.5rem;
  }
  :global(.lg-ml1){
    margin-left:0.5rem;
  }
  :global(.lg-mr1){
    margin-right:0.5rem;
  }

  :global(.lg-m2){
    margin:1rem;
  }

  :global(.lg-mx2){
    margin-left:1rem;
    margin-right:1rem;
  }

  :global(.lg-my2){
    margin-top:1rem;
    margin-bottom:1rem;
  }

  :global(.lg-mt2){
    margin-top:1rem;
  }
  :global(.lg-mb2){
    margin-bottom:1rem;
  }
  :global(.lg-ml2){
    margin-left:1rem;
  }
  :global(.lg-mr2){
    margin-right:1rem;
  }

  :global(.lg-m3){
    margin:1.5rem;
  }

  :global(.lg-mx3){
    margin-left:1.5rem;
    margin-right:1.5rem;
  }

  :global(.lg-my3){
    margin-top:1.5rem;
    margin-bottom:1.5rem;
  }

  :global(.lg-mt3){
    margin-top:1.5rem;
  }
  :global(.lg-mb3){
    margin-bottom:1.5rem;
  }
  :global(.lg-ml3){
    margin-left:1.5rem;
  }
  :global(.lg-mr3){
    margin-right:1.5rem;
  }

  :global(.lg-m4){
    margin:2rem;
  }

  :global(.lg-mx4){
    margin-left:2rem;
    margin-right:2rem;
  }

  :global(.lg-my4){
    margin-top:2rem;
    margin-bottom:2rem;
  }

  :global(.lg-mt4){
    margin-top:2rem;
  }
  :global(.lg-mb4){
    margin-bottom:2rem;
  }
  :global(.lg-ml4){
    margin-left:2rem;
  }
  :global(.lg-mr4){
    margin-right:2rem;
  }
}
@media screen and (min-width: 120em){
  :global(.xl-p0){
    padding:0;
  }
  :global(.xl-pt0){
    padding-top:0;
  }
  :global(.xl-pb0){
    padding-bottom:0;
  }
  :global(.xl-pl0){
    padding-left:0;
  }
  :global(.xl-pr0){
    padding-right:0;
  }
  :global(.xl-p1){
    padding:0.5rem;
  }

  :global(.xl-px1){
    padding-left:0.5rem;
    padding-right:0.5rem;
  }

  :global(.xl-py1){
    padding-top:0.5rem;
    padding-bottom:0.5rem;
  }

  :global(.xl-pt1){
    padding-top:0.5rem;
  }
  :global(.xl-pb1){
    padding-bottom:0.5rem;
  }
  :global(.xl-pl1){
    padding-left:0.5rem;
  }
  :global(.xl-pr1){
    padding-right:0.5rem;
  }

  :global(.xl-p2){
    padding:1rem;
  }

  :global(.xl-px2){
    padding-left:1rem;
    padding-right:1rem;
  }

  :global(.xl-py2){
    padding-top:1rem;
    padding-bottom:1rem;
  }

  :global(.xl-pt2){
    padding-top:1rem;
  }
  :global(.xl-pb2){
    padding-bottom:1rem;
  }
  :global(.xl-pl2){
    padding-left:1rem;
  }
  :global(.xl-pr2){
    padding-right:1rem;
  }

  :global(.xl-p3){
    padding:1.5rem;
  }

  :global(.xl-px3){
    padding-left:1.5rem;
    padding-right:1.5rem;
  }

  :global(.xl-py3){
    padding-top:1.5rem;
    padding-bottom:1.5rem;
  }

  :global(.xl-pt3){
    padding-top:1.5rem;
  }
  :global(.xl-pb3){
    padding-bottom:1.5rem;
  }
  :global(.xl-pl3){
    padding-left:1.5rem;
  }
  :global(.xl-pr3){
    padding-right:1.5rem;
  }

  :global(.xl-p4){
    padding:2rem;
  }

  :global(.xl-px4){
    padding-left:2rem;
    padding-right:2rem;
  }

  :global(.xl-py4){
    padding-top:2rem;
    padding-bottom:2rem;
  }

  :global(.xl-pt4){
    padding-top:2rem;
  }
  :global(.xl-pb4){
    padding-bottom:2rem;
  }
  :global(.xl-pl4){
    padding-left:2rem;
  }
  :global(.xl-pr4){
    padding-right:2rem;
  }
  :global(.xl-m0){
    margin:0;
  }
  :global(.xl-mt0){
    margin-top:0;
  }
  :global(.xl-mb0){
    margin-bottom:0;
  }
  :global(.xl-ml0){
    margin-left:0;
  }
  :global(.xl-mr0){
    margin-right:0;
  }
  :global(.xl-m1){
    margin:0.5rem;
  }

  :global(.xl-mx1){
    margin-left:0.5rem;
    margin-right:0.5rem;
  }

  :global(.xl-my1){
    margin-top:0.5rem;
    margin-bottom:0.5rem;
  }

  :global(.xl-mt1){
    margin-top:0.5rem;
  }
  :global(.xl-mb1){
    margin-bottom:0.5rem;
  }
  :global(.xl-ml1){
    margin-left:0.5rem;
  }
  :global(.xl-mr1){
    margin-right:0.5rem;
  }

  :global(.xl-m2){
    margin:1rem;
  }

  :global(.xl-mx2){
    margin-left:1rem;
    margin-right:1rem;
  }

  :global(.xl-my2){
    margin-top:1rem;
    margin-bottom:1rem;
  }

  :global(.xl-mt2){
    margin-top:1rem;
  }
  :global(.xl-mb2){
    margin-bottom:1rem;
  }
  :global(.xl-ml2){
    margin-left:1rem;
  }
  :global(.xl-mr2){
    margin-right:1rem;
  }

  :global(.xl-m3){
    margin:1.5rem;
  }

  :global(.xl-mx3){
    margin-left:1.5rem;
    margin-right:1.5rem;
  }

  :global(.xl-my3){
    margin-top:1.5rem;
    margin-bottom:1.5rem;
  }

  :global(.xl-mt3){
    margin-top:1.5rem;
  }
  :global(.xl-mb3){
    margin-bottom:1.5rem;
  }
  :global(.xl-ml3){
    margin-left:1.5rem;
  }
  :global(.xl-mr3){
    margin-right:1.5rem;
  }

  :global(.xl-m4){
    margin:2rem;
  }

  :global(.xl-mx4){
    margin-left:2rem;
    margin-right:2rem;
  }

  :global(.xl-my4){
    margin-top:2rem;
    margin-bottom:2rem;
  }

  :global(.xl-mt4){
    margin-top:2rem;
  }
  :global(.xl-mb4){
    margin-bottom:2rem;
  }
  :global(.xl-ml4){
    margin-left:2rem;
  }
  :global(.xl-mr4){
    margin-right:2rem;
  }
}
:root{
  --70-percent-black:#4c5773;
}
:global(.text-centered),
.text-centered{
  text-align:center;
}
@media screen and (min-width: 40em){
  :global(.sm-text-centered){
    text-align:center;
  }
}
@media screen and (min-width: 60em){
  :global(.md-text-centered){
    text-align:center;
  }
}
@media screen and (min-width: 80em){
  :global(.lg-text-centered){
    text-align:center;
  }
}
@media screen and (min-width: 120em){
  :global(.xl-text-centered){
    text-align:center;
  }
}
:global(.text-left),
.text-left{
  text-align:left;
}
@media screen and (min-width: 40em){
  :global(.sm-text-left){
    text-align:left;
  }
}
@media screen and (min-width: 60em){
  :global(.md-text-left){
    text-align:left;
  }
}
@media screen and (min-width: 80em){
  :global(.lg-text-left){
    text-align:left;
  }
}
@media screen and (min-width: 120em){
  :global(.xl-text-left){
    text-align:left;
  }
}
:global(.text-right),
.text-right{
  text-align:right;
}
@media screen and (min-width: 40em){
  :global(.sm-text-right){
    text-align:right;
  }
}
@media screen and (min-width: 60em){
  :global(.md-text-right){
    text-align:right;
  }
}
@media screen and (min-width: 80em){
  :global(.lg-text-right){
    text-align:right;
  }
}
@media screen and (min-width: 120em){
  :global(.xl-text-right){
    text-align:right;
  }
}
:global(.text-uppercase),
.text-uppercase{
  text-transform:uppercase;
  letter-spacing:0.06em;
}
:global(.text-lowercase){
  text-transform:lowercase;
}
:global(.text-capitalize){
  text-transform:capitalize;
}
:global(.text-transform-none){
  text-transform:none;
}
:global(.text-normal){
  font-weight:400;
}
:global(.text-bold),
.text-bold{
  font-weight:700;
}
:global(.text-heavy){
  font-weight:900;
}
:global(.text-italic){
  font-style:italic;
}
:global(.text-body),
.text-body{
  font-size:1.286em;
  line-height:1.457em;
  color:#696e7b;
}
:global(.text-list){
  font-size:1em;
}
:global(.text-paragraph),
.text-paragraph{
  font-size:1.143em;
  line-height:1.5em;
}
:global(.text-spaced),
.text-spaced{
  line-height:1.5em;
}
:global(.text-unspaced),
.text-unspaced{
  line-height:normal;
}
:global(.text-small){
  font-size:0.875em;
}
:global(.text-smaller){
  font-size:0.8em;
}
:global(.text-current){
  color:currentColor;
}
:global(.text-underline){
  text-decoration:underline;
}
:global(.text-underline-hover:hover){
  text-decoration:underline;
}
:global(.text-ellipsis){
  text-overflow:ellipsis;
}
:global(.text-wrap){
  word-wrap:anywhere;
  word-break:break-word;
  word-wrap:anywhere;
}
:global(.text-nowrap){
  white-space:nowrap;
}
:global(.text-code){
  font-family:monospace;
  color:#696e7b;
  background-color:#edf2f5;
  border-radius:2px;
  padding:0.2em 0.4em;
  line-height:1.4em;
  white-space:pre-wrap;
}
:global(.text-code-plain){
  font-family:monospace;
  color:#4c5773;
  line-height:1.4em;
  white-space:pre-wrap;
}
:global(.text-monospace),
.text-monospace{
  font-family:Monaco, monospace;
}
:global(.text-pre-wrap){
  white-space:pre-wrap;
}
:global(.text-measure){
  max-width:620px;
}
:global(.break-anywhere){
  line-break:anywhere;
}
:global(.w-full),
.w-full{
  width:100%;
}
