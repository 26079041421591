:global(.saveQuestionModalFields) {
  overflow: hidden;
}

:global(.saveQuestionModalFields-enter) {
  max-height: 0px;
}
:global(.saveQuestionModalFields-enter.saveQuestionModalFields-enter-active) {
  /* using 100% max-height breaks the transition */
  max-height: 300px;
  transition: max-height 500ms ease-out;
}
:global(.saveQuestionModalFields-exit) {
  max-height: 300px;
}
:global(.saveQuestionModalFields-exit.saveQuestionModalFields-exit-active) {
  max-height: 0px;
  transition: max-height 500ms ease-out;
}
