:root {
  --default-header-margin: 0;
}

h1,
:global(.h1),
h2,
:global(.h2),
h3,
:global(.h3),
h4,
:global(.h4),
h5,
:global(.h5),
h6,
:global(.h6) {
  font-weight: 700;
  margin-top: var(--default-header-margin);
  margin-bottom: var(--default-header-margin);
}

/**
 * Correct the font size on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 * https://github.com/necolas/normalize.css/blob/fc091cc/normalize.css#L40
 */
h1 {
  font-size: 2em;
}

:global(.h1) {
  font-size: 2em;
}
:global(.h2) {
  font-size: 1.5em;
}
:global(.h3) {
  font-size: 1.17em;
}
:global(.h4) {
  font-size: 1.12em;
}
:global(.h5) {
  font-size: 0.83em;
}
:global(.h6) {
  font-size: 0.75em;
}

@media screen and (--breakpoint-min-sm) {
  :global(.sm-h1) {
    font-size: 2em;
  }
  :global(.sm-h2) {
    font-size: 1.5em;
  }
  :global(.sm-h3) {
    font-size: 1.17em;
  }
  :global(.sm-h4) {
    font-size: 1.12em;
  }
  :global(.sm-h5) {
    font-size: 0.83em;
  }
  :global(.sm-h6) {
    font-size: 0.75em;
  }
}

@media screen and (--breakpoint-min-md) {
  :global(.md-h1) {
    font-size: 2em;
  }
  :global(.md-h2) {
    font-size: 1.5em;
  }
  :global(.md-h3) {
    font-size: 1.17em;
  }
  :global(.md-h4) {
    font-size: 1.12em;
  }
  :global(.md-h5) {
    font-size: 0.83em;
  }
  :global(.md-h6) {
    font-size: 0.75em;
  }
}

@media screen and (--breakpoint-min-lg) {
  :global(.lg-h1) {
    font-size: 2em;
  }
  :global(.lg-h2) {
    font-size: 1.5em;
  }
  :global(.lg-h3) {
    font-size: 1.17em;
  }
  :global(.lg-h4) {
    font-size: 1.12em;
  }
  :global(.lg-h5) {
    font-size: 0.83em;
  }
  :global(.lg-h6) {
    font-size: 0.75em;
  }
}
