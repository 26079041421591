/* NOTE: DO NOT ADD COLORS WITHOUT EXTREMELY GOOD REASON AND DESIGN REVIEW
 * NOTE: KEEP SYNCRONIZED WITH COLORS.JS
 */
:root {
  --color-admin-navbar: #7172ad;
  --color-white: #ffffff;
  --color-black: #2e353b;
  --color-success: #84bb4c;
  --color-error: #ed6e6e;
  --color-warning: #f9cf48;
  --color-text-dark: #4c5773;
  --color-text-medium: #696e7b;
  --color-text-light: #949aab;
  --color-text-white: #ffffff;
  --color-bg-black: #2e353b;
  --color-bg-dark: #93a1ab;
  --color-bg-medium: #edf2f5;
  --color-bg-light: #f9fbfc;
  --color-bg-white: #ffffff;
  --color-bg-yellow: #fffcf2;
  --color-shadow: rgba(0, 0, 0, 0.13);
  --color-border: #eeecec;
}

:global(.text-default),
.text-default {
  color: var(--color-text-dark);
}

:global(.text-default-hover:hover) {
  color: var(--color-text-dark);
}

/* brand */
:global(.text-brand),
.text-brand {
  color: var(--color-brand);
}

/* success */

:global(.text-success) {
  color: var(--color-success);
}

/* error */

:global(.text-error),
.text-error,
:global(.text-error-hover:hover) {
  color: var(--color-error);
}

:global(.bg-error-hover:hover) {
  background-color: var(--color-error);
}
:global(.bg-error-input) {
  background-color: var(--color-bg-white);
}

:global(.text-slate) {
  color: var(--color-text-medium);
}
:global(.text-slate-light) {
  color: var(--color-text-light);
}

:global(.text-light),
.text-light {
  color: var(--color-text-light);
}

:global(.text-medium),
.text-medium,
:global(.text-medium-hover:hover) {
  color: var(--color-text-medium);
}

:global(.text-dark),
.text-dark,
:global(.text-dark-hover:hover) {
  color: var(--color-text-dark);
}

:global(.bg-light),
:global(.bg-light-hover:hover) {
  background-color: var(--color-bg-light);
}

:global(.bg-medium) {
  background-color: var(--color-bg-medium);
}

/* white  - move to bottom for specificity since its often used on hovers, etc */
:global(.text-white),
.text-white,
:global(.text-white-hover:hover) {
  color: var(--color-text-white);
}

:global(.bg-white),
.bg-white {
  background-color: var(--color-bg-white);
}

:global(.bg-light-blue) {
  background-color: var(--color-bg-light);
}
