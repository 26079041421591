:global(.Calendar-weeks) {
  padding-bottom: 14px;
}

:global(.Calendar-week) {
  display: flex;
  padding-left: 12px;
  padding-right: 12px;
}

:global(.Calendar-header) {
  padding: 14px;
  margin-bottom: 8px;
}

:global(.Calendar-header h4) {
  font-weight: 700;
}

:global(.Calendar-day-names) {
  opacity: 0.12;
}

:global(.Calendar-day),
:global(.Calendar-day-name) {
  flex: 1;
}

:global(.Calendar-day) {
  color: var(--color-text-light);
  position: relative;
  border-radius: 99px;
  font-weight: 700;
  font-size: 12px;
  background-color: transparent;
  padding: 10px;
}

:global(.Calendar-day-name) {
  cursor: inherit;
}

:global(.Calendar-day--this-month) {
  color: currentcolor;
}

:global(.Calendar-day-name) {
  color: inherit !important;
}

:global(.Calendar--range .Calendar-day--selected) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

:global(.Calendar--range .Calendar-day--selected-end) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

:global(.Calendar-day--in-range) {
  border-radius: 0;
  background-color: var(--color-bg-medium);
}

:global(.Calendar--noContext .Calendar-day) {
  visibility: hidden;
  pointer-events: none;
}

:global(.Calendar--noContext .Calendar-day--this-month) {
  visibility: visible;
  pointer-events: all;
}
